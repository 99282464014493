
import { updateList, updateListUserPermissions } from "~/helpers/apollo/apollo-list-query-helpers.js";

import { USER_PERMISSION_NONE, LIST_PERMISSION_USER_DEFINED } from "./constants.js";

import UserPermissions from "./UserPermissions.vue";
import { mapWritableState } from "pinia";
import { useChromeStore } from "~/composables/stores/chrome";

export default {
  components: { UserPermissions },

  props: {
    list: {
      type: Object,
      default: function () {
        return {
          name: "",
          description: "",
          privacy: "",
        };
      },
    },

    users: {
      type: Array,
      required: true,
    },

    visible: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      listName: "",
      listDescription: "",
      listPermission: "",
      blockSubmit: false,
      usersIntermediate: this.users,
    };
  },

  watch: {
    visible(value) {
      this.listName = this.list.name;
      this.listDescription = this.list.description;
      this.listPermission = this.list.privacy;
      this.blockSubmit = false;
    },
  },

  methods: {
    saveList: function () {
      if (this.listName !== "") {
        this.$amplitude.track({ event_type: "Edit List" });

        updateList.call(this, this.list.id, this.listName, this.listDescription, this.listPermission).finally(() => {
          this.blockSubmit = true;

          if (this.listPermission === LIST_PERMISSION_USER_DEFINED) {
            const userPermissions = this.usersIntermediate
              .filter((u) => u.permission !== USER_PERMISSION_NONE)
              .map((u) => {
                return {
                  userId: u.id,
                  permission: u.permission,
                };
              });

            updateListUserPermissions.call(this, this.list.id, userPermissions).finally(() => {
              this.blockSubmit = false;
              this.$emit("saved");
            });
          } else {
            this.$emit("saved");
          }
        });
      }
    },
  },
};
