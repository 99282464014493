// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_Y\\+iPN{display:flex;flex-direction:column;height:100%;padding:1rem;position:relative;width:100%}.slotControls_kG7BL{display:flex;justify-content:flex-end;margin-bottom:1.25rem}.slotTop_qmTwH{display:flex;flex-direction:column;margin-bottom:1.75rem}.split_J2v1W{display:flex;height:100%;overflow:hidden;width:100%}.slotBottomEnd_0oSfe,.slotBottomStart_d5bZX,.slotBottomStartControls_ZYGBT{display:flex;flex-direction:column;padding-right:1rem}.slotBottomStartControls_ZYGBT{margin-top:1.25rem}.overflow_7Kd13{-webkit-overflow-scrolling:touch;display:flex;flex-direction:column;height:100%;overflow:hidden;overflow-y:auto;scroll-behavior:smooth;scrollbar-color:var(--color-supportive-3-500) var(--color-supportive-3-100);scrollbar-width:thin}.overflow_7Kd13::-webkit-scrollbar{height:1rem}.overflow_7Kd13::-webkit-scrollbar-track{background:var(--color-supportive-3-100)}.overflow_7Kd13::-webkit-scrollbar-thumb{background-color:var(--color-supportive-3-500);border:.25rem solid var(--color-supportive-3-100);border-radius:calc(1rem - var(--border-radius-medium))}.overflow_7Kd13::-webkit-scrollbar-thumb:hover{border-width:.2rem}.slotBottomStart_d5bZX{padding-right:0;width:100%}.slotBottomEnd_0oSfe{min-width:-moz-fit-content;min-width:fit-content}.slotBottomEndBorder_7ib\\+Z{border-left:1px solid var(--color-supportive-3-300);display:flex;flex-direction:column;padding-left:1.25rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_Y+iPN",
	"slotControls": "slotControls_kG7BL",
	"slotTop": "slotTop_qmTwH",
	"split": "split_J2v1W",
	"slotBottomEnd": "slotBottomEnd_0oSfe",
	"slotBottomStart": "slotBottomStart_d5bZX",
	"slotBottomStartControls": "slotBottomStartControls_ZYGBT",
	"overflow": "overflow_7Kd13",
	"slotBottomEndBorder": "slotBottomEndBorder_7ib+Z"
};
module.exports = ___CSS_LOADER_EXPORT___;
